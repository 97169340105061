export interface GetUserInfo {
  birthday: string;
  staffCode: string;
  email: string;
  firstName: string;
  gender: string;
  id: string;
  isSubscribeMonthlyReport: boolean;
  lastName: string;
  phoneNumber: string;
  postalAddress: string;
  referralBy: string;
  referralCode: string;
  referralUrl: string;
  state: string;
  userRole: string;
}

export enum Gender {
  FEMALE = "female",
  MALE = "male",
}

export enum ConfirmationStatus {
  PENDING = "PENDING",
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
}

export interface UpdateUserInfo {
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  birthday?: string;
  phoneNumber?: string;
  postalAddress?: string;
  isSubscribeMonthlyReport?: boolean;
}
