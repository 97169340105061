import { navigate } from "gatsby";
import React from "react";
import { useQueryClient } from "react-query";
import CustomButton, {
  CustomButtonOutLine,
} from "../../components/custom-button";
import {
  useUpdateConfirmationData,
  useUserConfimationData,
} from "../../hooks/user.hooks.ts";
import { ConfirmationStatus } from "../../types/user.types.ts";

const ShareData = ({ id }) => {
  const queryClient = useQueryClient();
  const { data: userConfirmation, isError } = useUserConfimationData(id);
  const updateUserConfirmation = useUpdateConfirmationData();
  const handleSubmitShareUser = async (status) => {
    updateUserConfirmation.mutate(
      {
        id,
        status,
      },
      {
        onSuccess: () => {
          navigate("/");
          queryClient.invalidateQueries(id);
        },
      }
    );
  };
  return (
    <div>
      {isError ? (
        <div className="confirmation-content">
          <div className="confirmation-content__header">Invalid request</div>
          <div>
            Your request is invalid. Please contact us if you have any
            questions.
          </div>
        </div>
      ) : (
        <div className="confirmation-content">
          <div className="confirmation-content__header">
            Share data confirmation
          </div>
          <div>
            <span className="font-bold">{userConfirmation?.staffName}</span>{" "}
            wants to share your details with <br />
            <span className="font-bold">
              {userConfirmation?.targetStaffName}
            </span>{" "}
            from{" "}
            <span className="font-bold">{userConfirmation?.companyName}</span>
          </div>
          <div className="submit-button-group">
            <CustomButton
              label="Accept"
              onKeyPress={() => {}}
              onClick={() => {
                handleSubmitShareUser(ConfirmationStatus.ACCEPT);
              }}
            />
            <CustomButtonOutLine
              label="Reject"
              onKeyPress={() => {}}
              onClick={() => {
                handleSubmitShareUser(ConfirmationStatus.REJECT);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ShareData.propTypes = {};

export default ShareData;
