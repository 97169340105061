/* eslint-disable consistent-return */
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { useLayoutEffect } from "react";
import { ConfirmationType } from "../commons/enum";
import BackToLoginButton from "../components/back-to-login-button";
import JointAccount from "../features/confirmation/joint-account";
import ShareData from "../features/confirmation/share-data";
import { useIsLoggedIn } from "../hooks/user.hooks.ts";
import "../scss/confirmation.scss";

const Confirmation = () => {
  const { search } = useLocation();
  const id = new URLSearchParams(search).get("id");
  const token = new URLSearchParams(search).get("token");
  const isLoggedIn = useIsLoggedIn();
  const confirmationType = new URLSearchParams(search).get("type");
  const generateConfirmationData = (type) => {
    switch (type) {
      case ConfirmationType.SHARE_DATA:
        return <ShareData id={id} />;
      case ConfirmationType.JOINT_ACCOUNT:
        return <JointAccount token={token} />;
      default:
        break;
    }
  };

  useLayoutEffect(() => {
    if (!isLoggedIn) {
      navigate(
        `/signup?redirect=${encodeURIComponent(
          `/confirmation${window.location.search}`
        )}`
      );
    }
  }, [isLoggedIn]);

  return (
    <div>
      <div className="confirmation">
        <div className="confirmation__body">
          {generateConfirmationData(confirmationType)}
          <div className="back-to-login-button-container">
            <BackToLoginButton />
          </div>
        </div>
      </div>
    </div>
  );
};

Confirmation.propTypes = {};

export default Confirmation;
