import { navigate } from "gatsby";
import { useSnackbar } from "notistack";
import React from "react";
import { useQueryClient } from "react-query";
import CustomButton, {
  CustomButtonOutLine,
} from "../../components/custom-button";
import LoadingSpinner from "../../components/loading-spinner";
import {
  useAcceptJointAccountInvitation,
  useDeleteJointAccountConnection,
  useValidateInvitationToken,
} from "../../hooks/joint-account.hooks";

const JointAccount = ({ token }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    data: jointAccountConnnection,
    isError,
    error,
    isFetching,
    isLoading,
  } = useValidateInvitationToken(token);

  const deleteJointAccountConnection = useDeleteJointAccountConnection();
  const acceptInvitation = useAcceptJointAccountInvitation();
  const handleSubmitJointAccount = () => {
    acceptInvitation.mutate(
      {
        token,
      },
      {
        onSuccess: () => {
          navigate("/account/joint-accounts");
          queryClient.invalidateQueries([
            "jointAccountConnection",
            jointAccountConnnection.id,
          ]);
          enqueueSnackbar("Accept join Joint Account successfully", {
            variant: "success",
          });
        },
      }
    );
  };

  const handleRejectJointAccount = () => {
    deleteJointAccountConnection.mutate(jointAccountConnnection.id, {
      onSuccess: () => {
        navigate("/");
        queryClient.invalidateQueries([
          "jointAccountConnection",
          jointAccountConnnection.id,
        ]);
        enqueueSnackbar("Reject join Joint Account successfully", {
          variant: "success",
        });
      },
    });
  };

  if (isFetching && isLoading) {
    return (
      <div className="confirmation-content">
        <LoadingSpinner />
        <span>Validating...</span>
      </div>
    );
  }

  return (
    <>
      {isError ? (
        <div className="confirmation-content">
          <div className="confirmation-content__header">Invalid request</div>
          <div>
            {error?.data?.message ||
              "Your request is invalid. Please contact us if you have any questions"}
          </div>
        </div>
      ) : (
        <div className="confirmation-content">
          <div className="confirmation-content__header">
            Joint Account confirmation
          </div>
          <div>
            <span className="font-bold">
              {jointAccountConnnection?.jointAccount?.owner.firstName}{" "}
              {jointAccountConnnection?.jointAccount?.owner.lastName}
            </span>{" "}
            wants to invite you join a Joint Account <br />
          </div>
          <div className="submit-button-group">
            <CustomButton
              label="Accept"
              onKeyPress={() => {}}
              onClick={() => {
                handleSubmitJointAccount();
              }}
            />
            <CustomButtonOutLine
              label="Reject"
              onKeyPress={() => {}}
              onClick={handleRejectJointAccount}
            />
          </div>
        </div>
      )}
    </>
  );
};

JointAccount.propTypes = {};

export default JointAccount;
