import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "gatsby";
import React from "react";

const BackToLoginButton = () => {
  const redirect =
    new URLSearchParams(window.location.search).get("redirect") || "/";
  return (
    <div className="text-left">
      <Link className="back-to-login" to={`/login?redirect=${redirect}`}>
        <ArrowBackIcon className="back-icon" fontSize="small" />
        Back to Login Page
      </Link>
    </div>
  );
};

export default BackToLoginButton;
